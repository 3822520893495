<template>
  <div id="roomlist" style="max-height: 100vh" class="overflow-y-auto">
    <v-card class="mx-auto" max-width="500" tile elevation="0">
      <v-toolbar :elevation="0">
        <v-toolbar-title>
          <img id="header-logo" src="@/assets/JA_Circle-Outline.png" />
          <!-- <img id="header-logo" src="@/assets/JA_Horizontal.png" /> -->
          <!-- <img id="header-logo" src="@/assets/JA_Circle-White_blackText.png" /> -->
        </v-toolbar-title>
        <!-- <v-toolbar-title>JA</v-toolbar-title> -->
      </v-toolbar>
      <v-list>
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-simple-table>
              <tbody>
                <tr>
                  <td>Number of Modules</td>
                  <td>{{ moduleCount() }}</td>
                </tr>
                <tr>
                  <td>Building Length</td>
                  <td>{{ bldgLength() }} ft.</td>
                </tr>
                <tr>
                  <td>Square Footage</td>
                  <td>{{ bldgArea() }} sq.ft.</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <emailDialog
                      v-bind:casita-code="bldgLinkCode"
                    ></emailDialog>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
      <help></help>
      <v-list>
        <v-list-item class="px-4">
          <v-list-item-content>
            <v-list-title class="pb-6">MODULES</v-list-title>

            <v-list-item
              v-for="room in filterRooms"
              :key="room.id"
              v-model="room.active"
              class="px-0"
            >
              <v-list-item-content>
                <v-row class="mx-0 mb-0" justify="space-between">
                  <div>
                    <span>
                      {{ roomCard[room.geo].name }}
                    </span>
                    <span class="area">{{ roomArea(room) }} sq. ft.</span>
                  </div>
                  <v-dialog max-width="600" :key="room.id">
                    <template #activator="{ on: dialog }">
                      <v-tooltip right allow-overflow>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            plain
                            color="primary"
                            v-on="{ ...tooltip, ...dialog }"
                          >
                            <v-icon>mdi-information</v-icon>
                          </v-btn>
                        </template>
                        <span>See more info on this module.</span>
                      </v-tooltip>
                    </template>
                    <v-card>
                      <v-card-title>{{ room.name }}</v-card-title>
                      <v-card-subtitle>{{
                        roomCard[room.geo].desc
                      }}</v-card-subtitle>
                      <v-img
                        :src="roomCard[room.geo].img"
                        :lazy-src="roomCard[room.geo].img"
                        max-width="600"
                        max-height="800"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-dialog>
                </v-row>
                <!-- <span class="desc">{{ roomCard[room.geo].desc }}</span> -->
              </v-list-item-content>
            </v-list-item>
            <!-- </v-list-group> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-row class="text-center mx-0 px-0" justify="space-around" width="90%">
        <v-dialog v-model="modules" max-width="500" max-height="80%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on"
              >SEE ALL MODULES</v-btn
            >
          </template>
          <v-row class="ma-0">
            <v-col v-for="room in roomCard" :key="room.id" cols="12">
              <v-card color="#ddd">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title
                      class="headline"
                      v-text="room.name"
                    ></v-card-title>
                    <v-card-subtitle v-text="room.desc"></v-card-subtitle>
                  </div>
                  <v-avatar class="ma-3" size="200" tile>
                    <v-img :src="room.img"></v-img>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-dialog>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import roomCards from "@/assets/roomCards.json";
import emailDialog from "@/components/email-dialog";
import help from "@/components/help.vue";

export default {
  name: "roomList",
  props: ["casita-code"],
  components: {
    emailDialog,
    help,
  },
  data: function () {
    return {
      cardColor: "#dddddd",
      dialog: false,
      sqftCost: 350,
      casitaDepth: 16.0,
      rooms: [],
      exportCode: "",
      roomCard: roomCards.roomCards,
    };
  },

  methods: {
    addItems: function (updatedRooms) {
      this.rooms = [];
      updatedRooms.forEach((room, ind, array) => {
        this.rooms.push(room);
      });
    },
    roomArea: function (room) {
      return (room.width * 3.28084 * this.casitaDepth).toFixed(0);
    },
    bldgLength: function () {
      let len = 0;
      this.rooms.forEach((room, ind, array) => {
        len += room.width;
      });
      return (len * 3.28084).toFixed(0);
    },
    bldgArea: function () {
      let area = 0;
      this.rooms.forEach((room, ind, array) => {
        console.log("room.depth", room.depth);
        if (room.depth) {
          area += room.depth * room.width;
        }
      });
      return (area * 10.7639).toFixed(0);
    },
    moduleCount: function () {
      return this.rooms.length;
    },
    bldgCost: function () {
      let totalArea = 0;
      this.rooms.forEach((room, ind, array) => {
        totalArea += parseInt(this.roomArea(room));
      });
      return (totalArea * this.sqftCost).toLocaleString();
    },
  },
  computed: {
    filterRooms: function () {
      return this.rooms.filter((room) => this.roomCard[room.geo]);
    },
    setparams: function () {
      return this.emailParams();
    },
    bldgLinkCode: function () {
      let code = "";
      this.rooms.forEach((room) => {
        code += room.code;
      });
      return code;
    },
  },
};
</script>