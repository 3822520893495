import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import V3DApp from "../components/V3DApp.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:id',
    name: 'saved-id',
    component: V3DApp
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
