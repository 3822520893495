import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// import landing from "./components/landing.vue";

Vue.config.productionTip = false;
Vue.prototype.$log = console.log;

// for ts - extend Window interface
declare global {
    interface Window {
    vm:any;
  }
}

window.vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
