  <template>
  <v-app>
    <div id="app">
      <!-- <siteHeader></siteHeader> -->
      <room-list ref="roomList"></room-list>
      <V3DApp ref="V3DApp"></V3DApp>
      <popupApp ref="popupApp"></popupApp>
      <view-control></view-control>

      <div id="about-splash">
        <v-dialog v-model="dialogAbout" width="60%">
          <v-card>
            <v-fab-transition>
              <v-btn fab small absolute right text @click="dialogAbout = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-fab-transition>
            <about></about>
          </v-card>
        </v-dialog>
      </div>

      <div id="side-panel">
        <v-btn color="yellow" @click.stop="aboutDrawer = !aboutDrawer" tile>
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </div>
      <v-navigation-drawer v-model="aboutDrawer" absolute bottom right temporary width="40%">
        <about></about>
      </v-navigation-drawer>

    </div>
  </v-app>
</template>

<script>
import V3DApp from "./components/V3DApp.vue";
import popupApp from "./components/popup.vue";
// import siteHeader from "./components/header.vue";
import roomList from "./components/roomList.vue";
import viewControl from "./components/view-control.vue";
import about from "./components/about.vue";

export default {
  name: "App",
  data: function () {
    return {
      dialogAbout: true,
      aboutDrawer: false,
    };
  },
  components: {
    roomList,
    V3DApp,
    popupApp,
    // siteHeader,
    viewControl,
    about,
  },
};
</script>
<style>
@import "assets/main.css";

#side-panel {
  position: absolute;
  top: 10px;
  right: 0px;
}
</style>

