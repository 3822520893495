<template>
  <div :id="containerId">
    <div
      id="fullscreen_button"
      class="fullscreen-button fullscreen-open"
      title="Toggle fullscreen mode"
    ></div>
    <div id="preloader" class="preloader">
      <div id="loader_container">
        <div id="loader_image"></div>
        <div id="loading_percentage" class="text-block">0%</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as V3DApp from "../v3dApp/app.js";
console.log("V3DApp.vue");
export default {
  name: "V3DApp",

  data: function () {
    return {
      containerId: V3DApp.CONTAINER_ID,
    };
  },
  methods: {
    loadBldg: function (code) {
      let linkCode = "";
      if (this.$route.params.id) {
        linkCode = this.$route.params.id;
      } else {
        linkCode = code;
      }
      window.v3d.puzzles.procedures.load_bldg(linkCode);
    },
  },
  mounted: function () {
    V3DApp.createApp();
  },
};
</script>

<style>
@import "../v3dApp/app.css";
</style>
