<template>
  <!-- Include he following conditional to get click-throughs to work with IE -->

  <!--[if IE]>
    <style type="text/css">
      .sample {
        filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='sample_600x600.png', sizingMethod='scale');
        background: none !important;
      }
    </style>
  <![endif]-->
  <div :id="popupApp">
    <div id="popup" v-click-outside="closeConditional">
      <nav class="menu" id="menu">
        <div class="toggle" id="toggle">
          <i class="fas fa-times" id="times" @click="popup()"></i>
        </div>

        <template v-for="button in buttonlist">
          <template v-if="button.menu">
            <v-menu :key="button.id" style="z-index: 10000">
              <template v-slot:activator="{ on, attrs }">
                <a
                  href="#"
                  class="popup-buttons"
                  :id="button.id"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i :class="[button.button, 'tooltip']">
                    <span class="tooltiptext">{{ button.buttonName }}</span>
                  </i>
                </a>
              </template>
              <v-card style="max-height: 90vh">
                <template v-for="(item, i) in items[button.id]" link>
                  <v-card :key="i" @click="button.method(item)">
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <!-- {{ $log(item) }} -->
                      <!-- {{ $log(roomCard[item]) }} -->
                      <div>
                        <v-card-title
                          class="headline"
                          v-text="roomCard[item].name"
                        ></v-card-title>
                        <v-card-subtitle
                          v-text="roomCard[item].desc"
                        ></v-card-subtitle>
                      </div>
                      <v-avatar
                        class="ma-3 profile"
                        color="grey"
                        size="250"
                        tile
                      >
                        <v-img :src="roomCard[item].img"></v-img>
                      </v-avatar>
                    </div>
                  </v-card> </template
              ></v-card>
            </v-menu>
          </template>
          <template v-else>
            <a
              href="#"
              @click="button.method(clickedRoom.id)"
              class="popup-buttons"
              :id="button.id"
              :key="button.id"
            >
              <i :class="[button.button, 'tooltip']">
                <span class="tooltiptext">{{ button.buttonName }}</span>
              </i></a
            >
          </template>
        </template>
      </nav>
    </div>
  </div>
</template>

<script>
// import popuplistmenu from "popupListMenu.vue";
import roomCards from "@/assets/roomCards.json";

export default {
  name: "popupApp",

  data: function () {
    return {
      roomCard: roomCards.roomCards,
      popupInactive: true,
      popupHidden: true,
      items: [],
      clickedRoom: {},
      buttonlist: {
        swap: {
          button: "fas fa-random",
          buttonName: "Swap Module",
          id: "swap",
          menu: true,
          method: this.swapMethod,
        },
        insertright: {
          button: "fas fa-angle-double-right",
          buttonName: "Insert Module Right",
          id: "insert-right",
          menu: true,
          method: this.insertRightMethod,
        },
        insertleft: {
          button: "fas fa-angle-double-left",
          buttonName: "Insert Module Left",
          id: "insert-left",
          menu: true,
          method: this.insertLeftMethod,
        },
        moveleft: {
          button: "fas fa-arrow-left",
          buttonName: "Move Module Left",
          id: "move-left",
          menu: false,
          method: this.moveLeftMethod,
        },
        moveright: {
          button: "fas fa-arrow-right",
          buttonName: "Move Module Right",
          id: "move-right",
          menu: false,
          method: this.moveRightMethod,
        },
        remove: {
          button: "fas fa-trash-alt",
          buttonName: "Remove Module",
          id: "remove",
          menu: false,
          method: this.removeMethod,
        },
      },
      roomIndex: 0,
    };
  },

  methods: {
    swapMethod: function (insertedRoom) {
      window.v3d.puzzles.procedures._swap_rooms(insertedRoom, this.roomIndex);
    },
    insertRightMethod: function (insertedRoom) {
      window.v3d.puzzles.procedures._insert_right(insertedRoom, this.roomIndex);
    },
    insertLeftMethod: function (insertedRoom) {
      window.v3d.puzzles.procedures._insert_left(insertedRoom, this.roomIndex);
    },
    moveRightMethod: function () {
      window.v3d.puzzles.procedures._move_right(this.roomIndex);
      this.popup();
    },
    moveLeftMethod: function () {
      window.v3d.puzzles.procedures._move_left(this.roomIndex);
      this.popup();
    },
    removeMethod: function (removedRoom) {
      window.v3d.puzzles.procedures._remove(removedRoom, this.roomIndex);
      this.popup();
    },

    closeConditional: function () {
      if (this.popupHidden == false && this.popupInactive == false) {
        this.popup();
      }
      this.popupInactive = false;
    },

    isEmpty: function (obj) {
      if (Object.keys(obj).length === 0 && obj.constructor === Object) {
        return 0;
      }
      return 1;
    },

    popup: function (room, allRooms, rules, index) {
      // console.log("(popup()) ROOM TYPE: ");

      this.roomIndex = index;
      this.clickedRoom = room;
      this.items = [];

      if (rules) {
        for (const ruleslist in rules) {
          this.items = Object.assign(this.items, {
            [ruleslist]: rules[ruleslist],
          });
        }
        console.log("RULES LIST", this.items);
      }

      const buildPopup = async () => {
        let popupOptions = [];
        let swapBool = Object.keys(this.items["swap"]).length > 0;
        swapBool = swapBool ? 1 : 0;

        console.log("SWAP", swapBool);
        console.log("MOVE LEFT", this.items["move-left"]);
        console.log("MOVE RIGHT", this.items["move-right"]);
        console.log("REQUIRED", this.items["required"]);
        console.log("INSERT LEFT", this.isEmpty(this.items["insert-left"]));
        console.log("INSERT RIGHT", this.isEmpty(this.items["insert-right"]));

        const buttons = [
          "swap",
          "move-left",
          "move-right",
          "remove",
          "insert-right",
          "insert-left",
        ];

        popupOptions = [
          swapBool,
          this.items["move-left"],
          this.items["move-right"],
          this.items["required"],
          this.isEmpty(this.items["insert-right"]),
          this.isEmpty(this.items["insert-left"]),
        ];
        // console.log("POP UP OPTIONS: " + popupOptions);
        const vis = { 0: "hidden", 1: "visible" };
        for (let i = 0; i < 6; i++) {
          document.getElementById(buttons[i]).style.visibility =
            vis[popupOptions[i] + 0]; // add 0 to cast true or false into 1 or 0
        }
      };

      const showPopup = async () => {
        if (this.popupHidden == true) {
          const mousex = event.clientX;
          const mousey = event.clientY;
          const newposX = mousex - 50;
          const newposY = mousey - 50;

          document.getElementById("popup").style.transition = "0s";
          document.getElementById("menu").style.transition = "0.2s";
          document.getElementById("popup").style.left = `${newposX}px`;
          document.getElementById("popup").style.top = `${newposY}px`;
          document.getElementById("menu").style.transform = "scale(2)";
          document.getElementById("popup").style.transform = "scale(1)";
          this.popupHidden = false;
          console.log("this.popupHidden set to 1");
        } else {
          document.getElementById("menu").style.transition = "0.2s";
          document.getElementById("menu").style.transform = "scale(0)";
          document.getElementById("popup").style.transform = "scale(0)";
          document.getElementById("popup").style.transition = "0.2s";
          this.items = [];
          this.popupHidden = true;
          room = null;
        }
      };

      if (room) {
        // this.popupHidden = 0; // This is necessary for the first click to work
        buildPopup().then(showPopup());
      } else {
        showPopup();
        this.items = [];
      }
    },
  },
  mounted: function () {
    this.popupHidden = true;
  },
};
</script>

<style>
@import "../assets/popup.css";
</style>
