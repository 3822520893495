<template>
  <div class="button-container">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      width="600"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          Get link to your design <v-icon>mdi-link</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Here's your link to your personalized design.
        </v-card-title>
        <v-row align="center" justify="space-around" class="py-5 ma-0">
          <span id="code">
            http://dev-casita.netlify.app/{{ casitaCode }}</span>
          <v-btn @click="copyLink()" class="text-right">
            copy to clipboard
            <v-icon>mdi-link</v-icon>
          </v-btn>
          <input type="hidden" id="testing-code" :value="'http://dev-casita.netlify.app/'+casitaCode">
        </v-row>
        <v-divider></v-divider>
        <v-card-text id="emailField" class="pt-2">
          Send your link to an email below.
          <v-form v-model="valid">
            <v-text-field
              value="you@youemail.com"
              label="E-mail"
              v-model="clientEmail"
              :rules="emailRules"
            ></v-text-field>
            <v-btn @click.prevent="sendEmail()">SEND</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import emailjs from "emailjs-com";

export default {
  name: "emailDialog",
  props: ["casita-code"],
  data: () => ({
    emailparams: {},
    clientEmail: "",
  }),
  methods: {
    sendEmail: function (params) {
      this.emailParams();
      emailjs
        .send(
          "service_2lhlqgd",
          "template_i4ea3xj",
          this.emailparams,
          "user_ZtrNFIwuOA7Lh9OYrZNuZ"
        )
        .then(
          function (response) {
            console.log("Success!", response.status, response.text);
          },
          function (error) {
            console.log("Failure!", error);
          }
        );
    },
    emailParams: function () {
      this.emailparams = {
        toName: "to name",
        fromName: "Johnston Architects",
        message: "This message",
        toEmail: this.clientEmail,
        casitaCode: this.casitaCode,
      };
    },
    copyLink: function () {
      const testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        // alert("Testing code was copied " + msg);
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style>
.button-container {
  display: block;
  width: 100%;
  text-align: center;
}

</style>