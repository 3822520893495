var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100vh"},attrs:{"id":"roomlist"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500","tile":"","elevation":"0"}},[_c('v-toolbar',{attrs:{"elevation":0}},[_c('v-toolbar-title',[_c('img',{attrs:{"id":"header-logo","src":require("@/assets/JA_Circle-Outline.png")}})])],1),_c('v-list',[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',[_vm._v("Number of Modules")]),_c('td',[_vm._v(_vm._s(_vm.moduleCount()))])]),_c('tr',[_c('td',[_vm._v("Building Length")]),_c('td',[_vm._v(_vm._s(_vm.bldgLength())+" ft.")])]),_c('tr',[_c('td',[_vm._v("Square Footage")]),_c('td',[_vm._v(_vm._s(_vm.bldgArea())+" sq.ft.")])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('emailDialog',{attrs:{"casita-code":_vm.bldgLinkCode}})],1)])])])],1)],1),_c('v-divider')],1),_c('help'),_c('v-list',[_c('v-list-item',{staticClass:"px-4"},[_c('v-list-item-content',[_c('v-list-title',{staticClass:"pb-6"},[_vm._v("MODULES")]),_vm._l((_vm.filterRooms),function(room){return _c('v-list-item',{key:room.id,staticClass:"px-0",model:{value:(room.active),callback:function ($$v) {_vm.$set(room, "active", $$v)},expression:"room.active"}},[_c('v-list-item-content',[_c('v-row',{staticClass:"mx-0 mb-0",attrs:{"justify":"space-between"}},[_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.roomCard[room.geo].name)+" ")]),_c('span',{staticClass:"area"},[_vm._v(_vm._s(_vm.roomArea(room))+" sq. ft.")])]),_c('v-dialog',{key:room.id,attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"right":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"plain":"","color":"primary"}},Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)},[_c('span',[_vm._v("See more info on this module.")])])]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(room.name))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.roomCard[room.geo].desc))]),_c('v-img',{attrs:{"src":_vm.roomCard[room.geo].img,"lazy-src":_vm.roomCard[room.geo].img,"max-width":"600","max-height":"800"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1)})],2)],1)],1),_c('v-row',{staticClass:"text-center mx-0 px-0",attrs:{"justify":"space-around","width":"90%"}},[_c('v-dialog',{attrs:{"max-width":"500","max-height":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("SEE ALL MODULES")])]}}]),model:{value:(_vm.modules),callback:function ($$v) {_vm.modules=$$v},expression:"modules"}},[_c('v-row',{staticClass:"ma-0"},_vm._l((_vm.roomCard),function(room){return _c('v-col',{key:room.id,attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"#ddd"}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"headline",domProps:{"textContent":_vm._s(room.name)}}),_c('v-card-subtitle',{domProps:{"textContent":_vm._s(room.desc)}})],1),_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"200","tile":""}},[_c('v-img',{attrs:{"src":room.img}})],1)],1)])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }