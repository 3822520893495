<template>
  <div id="view-control-container">
    <v-btn
      @click="
        planview = !planview;
        planView();
      "
    >
      {{ planview ? "3D View" : "Plan View" }}
    </v-btn>
    <v-btn
      style="margin-left: 10px"
      @click="
        showroof = !showroof;
        hideRoof();
      " 
      :disabled="planview"
    >
      Toggle Roof Visiblity
    </v-btn>
    <v-btn
      style="margin-left: 10px"
      @click="
        planview = false;
        resetView();
      "
    >
      Reset View
    </v-btn>
  </div>
</template>


<script>
export default {
  name: "viewControl",
  data: () => ({
    planview: false,
    showroof: 0,
  }),

  methods: {
    planView: function () {
      window.v3d.puzzles.procedures.toggleCameraViews();
    },
    resetView: function () {
      window.v3d.puzzles.procedures.resetView();
    },
    hideRoof: function () {
      window.v3d.puzzles.procedures.roof_vis(this.showroof);
    },
  },
};
</script>